import React, { useState, useEffect } from 'react';
import { MessageCircle, Heart, ChevronLeft, ChevronRight } from 'lucide-react';
import './PornLanding.css';

const HeaderCarousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      title: "",
      subtitle: "",
      image: "fetishbanner.png"
    },
    {
      title: "Personalized Interactions",
      subtitle: "Connect on a deeper level",
      image: "https://thumbnails.faperoni.com/source/10268.jpg"
    },
    {
      title: "Always Available",
      subtitle: "Your companion, anytime",
      image: "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/3536d583-37dd-4c77-9799-e70ff77a9ead/dgygout-088b9067-87fd-4be5-87c8-54f15a972623.jpg/v1/fill/w_1280,h_960,q_75,strp/latex_mask_tw_by_m2_ai_latex_fetish_dgygout-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9OTYwIiwicGF0aCI6IlwvZlwvMzUzNmQ1ODMtMzdkZC00Yzc3LTk3OTktZTcwZmY3N2E5ZWFkXC9kZ3lnb3V0LTA4OGI5MDY3LTg3ZmQtNGJlNS04N2M4LTU0ZjE1YTk3MjYyMy5qcGciLCJ3aWR0aCI6Ijw9MTI4MCJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl19.i8w6Hl9EJW0Che2HEvZPn-yXTtKN9iWF76xqtB0OJq0"
    }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <div className="planding-carousel">
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`planding-slide ${index === currentSlide ? 'fade-in' : 'fade-out'}`}
        >
          <img
            src={slide.image}
            alt={slide.title}
            className="planding-slide-image"
          />
          <div className="planding-slide-overlay">
            <div className="planding-slide-content">
              <h1 className="planding-title">{slide.title}</h1>
              <p className="planding-subtitle">{slide.subtitle}</p>
            </div>
          </div>
        </div>
      ))}

      <div className="planding-dots">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentSlide(index)}
            className={`planding-dot ${index === currentSlide ? 'planding-dot-active' : ''}`}
          />
        ))}
      </div>
    </div>
  );
};

const Navbar = ({ activeTab, setActiveTab }) => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      
      // Make navbar visible when scrolling up or at the top
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  return (
    <nav className={`planding-navbar ${visible ? 'navbar-visible' : 'navbar-hidden'}`}>
      <div className="planding-navbar-content">
        <div className="planding-navbar-logo">
          Lollipop
        </div>
        
        <div className="planding-navbar-tabs">
          <button 
            className={`planding-tab ${activeTab === 'all' ? 'planding-tab-active' : ''}`}
            onClick={() => setActiveTab('all')}
          >
            All
          </button>
          <button 
            className={`planding-tab ${activeTab === 'vanilla' ? 'planding-tab-active' : ''}`}
            onClick={() => setActiveTab('vanilla')}
          >
            Vanilla
          </button>
          <button 
            className={`planding-tab ${activeTab === 'hardcore' ? 'planding-tab-active' : ''}`}
            onClick={() => setActiveTab('hardcore')}
          >
            Hardcore
          </button>
        </div>

        <div className="planding-navbar-actions">
          <button className="planding-login-btn">Join free!</button>
        </div>
      </div>
    </nav>
  );
};

const LandingPage = () => {
  const [activeTab, setActiveTab] = useState('all');

  const companions = [
    {
      id: 1,
      name: "Anna",
      age: 24,
      location: "BDSM",
      description: "Be my slave instead?",
      mainImage: "bdsm1.png",
      hoverImage: "bdsm2.png",
      interests: ["Dom", "Latex", "Anal"],
      category: "vanilla"
    },
    {
      id: 2,
      name: "Sofi",
      age: 19,
      location: "Furry",
      description: "What does the fox say?",
      mainImage: "fox1.png",
      hoverImage: "fox2.png",
      interests: ["Furry", "Fox"],
      category: "vanilla"
    },
    {
      id: 3,
      name: "Eliza",
      age: 21,
      location: "Secretary",
      description: "Let's talk about my raise.",
      mainImage: "office1.png",
      hoverImage: "office1.png",
      interests: ["Secretary", "Boss", "Submission"],
      category: "vanilla"
    },
    {
      id: 4,
      name: "Amaris",
      age: 24,
      location: "Goth mommy",
      description: "I can take care of you.",
      mainImage: "amaris3.png",
      hoverImage: "amaris2.png",
      interests: ["Goth"],
      category: "vanilla"
    },
    {
      id: 5,
      name: "Heidi",
      age: 22,
      location: "Cuckolding",
      description: "I'll fuck your best friend.",
      mainImage: "heidi1.png",
      hoverImage: "heidi2.png",
      interests: ["Cucking", "Voyeur", "Group sex"],
      category: "hardcore"
    },
    {
      id: 6,
      name: "Amelia",
      age: 23,
      location: "Bodyfluids",
      description: "Your best friend next door.",
      mainImage: "ameliagreeting.png",
      hoverImage: "abena3.png",
      interests: ["Watersports", "Spit", "Anal"],
      category: "hardcore"
    },
    {
      id: 7,
      name: "Anna Maria",
      age: 24,
      location: "BDSM",
      description: "Be my slave instead?",
      mainImage: "annamariagreeting.png",
      hoverImage: "nina1.png",
      interests: ["Dom", "Latex", "Anal"],
      category: "vanilla"
    },
    {
      id: 8,
      name: "Heidi",
      age: 22,
      location: "Cuckolding",
      description: "I'll fuck your best friend.",
      mainImage: "heidi1.png",
      hoverImage: "heidi2.png",
      interests: ["Cucking", "Voyeur", "Group sex"],
      category: "hardcore"
    }
  ];

  return (
    <div className="planding-container">
            <Navbar activeTab={activeTab} setActiveTab={setActiveTab} />

      <HeaderCarousel />

      <div className="planding-findyourgirl">
        <div className="planding-subtitle" style={{fontWeight:600}}>Find <b className="planding-highlight">your girl</b>.</div>
      </div>

      <div className="planding-grid">
        {companions
          .filter(companion => activeTab === 'all' || companion.category === activeTab)
          .map((companion) => (
            <CompanionCard key={companion.id} companion={companion} />
          ))}
      </div>
    </div>
  );
};

const CompanionCard = ({ companion }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div 
      className="planding-card"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="planding-card-image-container">
        <img
          src={companion.mainImage}
          alt={companion.name}
          className={`planding-card-image ${isHovered ? 'fade-out' : 'fade-in'}`}
        />
        <img
          src={companion.hoverImage}
          alt={`${companion.name} alternate`}
          className={`planding-card-image ${isHovered ? 'fade-in' : 'fade-out'}`}
        />
        
        {/* Gradient Overlay */}
        <div className="planding-card-overlay"></div>

        {/* Content Overlay */}
        <div className="planding-card-content">
          <div className="planding-card-top">
            {isHovered ?
            <button className="planding-chat-textbutton">
              Tap to chat!
            </button> :
            <button className="planding-chat-button">
              <MessageCircle />
            </button>
            }
          </div>

          <div className="planding-card-info">
            <div className="planding-card-primary">
              <div className="planding-card-name">{companion.name}</div>
              <span className="planding-card-age">{companion.age}</span>
            </div>

            <div className="planding-description-block">
              <div className="planding-card-description">{companion.description}</div>
            </div>

            <div className="planding-card-location">
              {isHovered ? <Heart fill="white" className="planding-location-icon" /> : <Heart className="planding-location-icon" />}
              <span>{companion.location}</span>
            </div>

            <div className="planding-card-interests">
              {companion.interests.map((interest, index) => (
                <span key={index} className="planding-interest-tag">
                  {interest}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;