/* global gtag stripe tolt_referral*/

import React, { useState, useEffect, useRef } from "react";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
} from "firebase/auth";
import "./CustomGirl.css";
import Swal from "sweetalert2";
import Drawer from "@mui/joy/Drawer";
import Box from "@mui/joy/Box";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import { set } from "react-ga";
import SignupModal from "./SignupModal";
import NewPaymentModal from "./NewPaymentModal";

const endpoint = "https://lollipopchat-ca692e070ff0.herokuapp.com"; //https://lollipopchat-ca692e070ff0.herokuapp.com

// Appearance options
const ethnicities = [
  { id: "european", label: "European", img: "customgirls/european.png" },
  { id: "latina", label: "Latina", img: "customgirls/latina.png" },
  { id: "asian", label: "Asian", img: "customgirls/asian.png" },
  { id: "african", label: "African", img: "customgirls/african.png" },
  { id: "middle_eastern", label: "Middle Eastern", img: "customgirls/middle_eastern.png" },
  { id: "indian", label: "Indian", img: "customgirls/indian.png" }
];

// Update the buildTypes and other constants remain the same
const buildTypes = [
  { id: "overweight, fat", label: "Chubby Build" },
  { id: "curvy, fit, muscular", label: "Fit Build" },
  { id: "fit, skinny", label: "Skinny Build" },
];

const hairColors = [
  { id: "ginger hair", label: "Ginger", color: "#D4501E" },
  { id: "blonde hair", label: "Blonde", color: "#F7DC6F" },
  { id: "brunette hair", label: "Brunette", color: "#4A312C" },
];

const personalityTemplates = [
  "Very cheerful, outgoing, and extroverted. Sexually open.",
  "Shy, introverted, and reserved. Sexually submissive.",
  "Creative and free-spirited, finding beauty in everything, sexually adventurous.",
  "Confident and ambitious, always striving for success, sexually dominant.",
  "Extremely horny. Sexually open to anything.",
];
const handleSignupSubmit = (credentials) => {
  // Handle the signup logic
  console.log(credentials);
};


const sweety = (message) => {
  Swal.fire({
    text: message,
    background: "rgba(255, 0, 189, 0.9)",
    position: "top",
    showConfirmButton: false,
    color: "white",
    backdrop: false,
    timer: 3000,
    timerProgressBar: true,
    fontSize: "12px",
    width: "100vw",
  });
};

const PaymentModal = ({
  onClose,
  user,
  endpoint,
  sweety,
  isSubscribed,
  subscriptionWithStripe,
  paymentWithStripe,
}) => {
  return (
    <div className="backdrop" onClick={onClose}>
      <div className="modal modal3" onClick={(e) => e.stopPropagation()}>
        <div className="stripePayments">
          <img src="stripe.png" width="32px" alt="Stripe" />
          Secure payments with Stripe
        </div>
        <div
          style={{
            position: "absolute",
            top: "5px",
            right: "5px",
            cursor: "pointer",
          }}
          onClick={onClose}
        >
          <img src="closeicon.png" width="24px" height="24px" />
        </div>

        <div className="payment-options">
          {!isSubscribed ? (
            // Show subscription options for non-subscribed users
            <>
              <div className="payment-card">
                <h3>Starter Fantasy</h3>
                <p>
                  Love pussy, but want a sweet deal? <br />
                  This is for you!
                </p>
                <div className="payButtonWrapper">
                  <a
                    className="payButton"
                    onClick={() =>
                      subscriptionWithStripe("price_1QGHBPEsKxxKHfJyyPE9PujY")
                    }
                  >
                    <span className="strike">€7.99</span> €2.99/month
                  </a>
                </div>
                <div className="tokenAmount">25 Monthly Tokens</div>
              </div>

              <div className="payment-card middle">
                <h3>Enhanced Ecstasy</h3>
                <p>
                  You're an absolute legend, who needs more than the average
                  guy.
                </p>
                <div className="payButtonWrapper">
                  <a
                    className="payButton"
                    onClick={() =>
                      subscriptionWithStripe("price_1QGfm8EsKxxKHfJy9FrKBIDs")
                    }
                  >
                    <span className="strike">€19.99</span> €9.99/month
                  </a>
                </div>
                <div className="tokenAmount">100 Monthly Tokens</div>
              </div>

              <div className="payment-card">
                <h3>Limitless Lust</h3>
                <p>
                  Johnny Sins himself is afraid of you. <br /> We are too.{" "}
                  <b>You're a king.</b>
                </p>
                <div className="payButtonWrapper">
                  <a
                    className="payButton"
                    onClick={() =>
                      subscriptionWithStripe("price_1QGfn9EsKxxKHfJyjfN7ms1R")
                    }
                  >
                    €49.99/month
                  </a>
                </div>
                <div className="tokenAmount">1000 Monthly Tokens</div>
              </div>
            </>
          ) : (
            // Show one-time purchase options for subscribed users
            <>
              <div className="payment-card">
                <h3>Starter Fantasy</h3>
                <p>
                  Love pussy, but want a sweet deal? <br />
                  This is for you!
                </p>
                <div className="payButtonWrapper">
                  <a
                    className="payButton"
                    onClick={() =>
                      paymentWithStripe("price_1OUnhhEsKxxKHfJy2BGMvbsK")
                    }
                  >
                    <span className="strike">€7.99</span> €2.99
                  </a>
                </div>
                <div className="tokenAmount">25 Tokens</div>
              </div>

              <div className="payment-card middle">
                <h3>Enhanced Ecstasy</h3>
                <p>
                  You're an absolute legend, who needs more than the average
                  guy.
                </p>
                <div className="payButtonWrapper">
                  <a
                    className="payButton"
                    onClick={() =>
                      paymentWithStripe("price_1OUnjlEsKxxKHfJy8urThzlC")
                    }
                  >
                    <span className="strike">€19.99</span> €9.99
                  </a>
                </div>
                <div className="tokenAmount">100 Tokens</div>
              </div>

              <div className="payment-card">
                <h3>Limitless Lust</h3>
                <p>
                  Johnny Sins himself is afraid of you. <br /> We are too.{" "}
                  <b>You're a king.</b>
                </p>
                <div className="payButtonWrapper">
                  <a
                    className="payButton"
                    onClick={() =>
                      paymentWithStripe("price_1OUnkXEsKxxKHfJysI3RH4pl")
                    }
                  >
                    €49.99
                  </a>
                </div>
                <div className="tokenAmount">1000 Tokens</div>
              </div>
            </>
          )}
        </div>

        <div className="disclaimer">
          {!isSubscribed
            ? "No hidden fees. Cancel at any time."
            : "One-time purchase. Tokens are added instantly to your account."}
        </div>
      </div>
    </div>
  );
};

const SideNav = ({
  user,
  credits,
  openPaymentModal,
  setIsSignupModalOpen,
  setIsFeedbackModalOpen, // Add this to props
  signOutUser,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      className="side-nav"
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
      style={{ width: isExpanded ? "240px" : "72px" }}
    >
      <div className="side-nav-content">
        <div className="side-nav-logo">
          <div className="logo-circle">
            <img src="icon.png" alt="Lollipop" width="32" height="32" />
          </div>
          <span className={`logo-text ${isExpanded ? "expanded" : ""}`}>
            Lollipop
          </span>
        </div>

        <div className="side-nav-items">
          {!user ? (
            <div
              className="side-nav-item"
              onClick={() => setIsSignupModalOpen(true)}
            >
              <div className="side-nav-icon">
                <img
                  src="icons/loginicon.svg"
                  width="24"
                  height="24"
                  alt="Profile"
                />
              </div>
              <div
                className={`side-nav-item-content ${
                  isExpanded ? "expanded" : ""
                }`}
              >
                <div className="item-title">Log In</div>
                <div className="item-subtitle">Start chatting</div>
              </div>
            </div>
          ) : (
            <>
              <div className="side-nav-item" onClick={openPaymentModal}>
                <div className="side-nav-icon">
                  <img
                    src="icons/crediticon.svg"
                    width="24"
                    height="24"
                    alt="Credits"
                  />
                </div>
                <div
                  className={`side-nav-item-content ${
                    isExpanded ? "expanded" : ""
                  }`}
                >
                  <div className="item-title">Get credits</div>
                  <div className="item-subtitle">
                    {credits > 0 ? `${credits} credits left` : "Buy credits"}
                  </div>
                </div>
              </div>

              <a
                href="https://billing.stripe.com/p/login/6oE7sC4UZ6DLcUg5kk"
                target="_blank"
                rel="noopener noreferrer"
                className="side-nav-item"
              >
                <div className="side-nav-icon">
                  <img
                    src="icons/stripeicon.svg"
                    width="24"
                    height="24"
                    alt="Manage"
                  />
                </div>
                <div
                  className={`side-nav-item-content ${
                    isExpanded ? "expanded" : ""
                  }`}
                >
                  <div className="item-title">Manage Plan</div>
                  <div className="item-subtitle">Subscription settings</div>
                </div>
              </a>

              <div className="side-nav-divider" />

              <a href="/" className="side-nav-item">
                <div className="side-nav-icon">
                  <img
                    src="icons/flameicon.svg"
                    width="24"
                    height="24"
                    alt="Generate"
                  />
                </div>
                <div
                  className={`side-nav-item-content ${
                    isExpanded ? "expanded" : ""
                  }`}
                >
                  <div className="item-title">Lollipop girls</div>
                  <div className="item-subtitle">Chat with Lollipop girls</div>
                </div>
              </a>

              <a href="/generate-image" className="side-nav-item">
                <div className="side-nav-icon">
                  <img
                    src="icons/hearticon.svg"
                    width="24"
                    height="24"
                    alt="Generate"
                  />
                </div>
                <div
                  className={`side-nav-item-content ${
                    isExpanded ? "expanded" : ""
                  }`}
                >
                  <div className="item-title">Generate</div>
                  <div className="item-subtitle">Create photos</div>
                </div>
              </a>

              <div
                className="side-nav-item"
                onClick={() => {setIsFeedbackModalOpen(true)}}
              >
                <div className="side-nav-icon">
                  <img
                    src="icons/feedbackicon.svg"
                    width="24"
                    height="24"
                    alt="Feedback"
                  />
                </div>
                <div
                  className={`side-nav-item-content ${
                    isExpanded ? "expanded" : ""
                  }`}
                >
                  <div className="item-title">Feedback</div>
                  <div className="item-subtitle">Get free tokens</div>
                </div>
              </div>
            </>
          )}

          {/* Common items for both logged in and logged out users */}
          <a
            href="https://lollipop.chat/affiliate"
            target="_blank"
            rel="noopener noreferrer"
            className="side-nav-item"
          >
            <div className="side-nav-icon">
              <img
                src="icons/affiliateicon.svg"
                width="24"
                height="24"
                alt="Affiliate"
              />
            </div>
            <div
              className={`side-nav-item-content ${
                isExpanded ? "expanded" : ""
              }`}
            >
              <div className="item-title">Affiliate</div>
              <div className="item-subtitle">Earn with us</div>
            </div>
          </a>

          <a
            href="https://lollipop.chat/partners"
            target="_blank"
            rel="noopener noreferrer"
            className="side-nav-item"
          >
            <div className="side-nav-icon">
              <img
                src="icons/partnersicon.svg"
                width="24"
                height="24"
                alt="Partners"
              />
            </div>
            <div
              className={`side-nav-item-content ${
                isExpanded ? "expanded" : ""
              }`}
            >
              <div className="item-title">Partners</div>
              <div className="item-subtitle">Meet our partners</div>
            </div>
          </a>

          <a href="mailto:lenny@lollipop.chat" className="side-nav-item">
            <div className="side-nav-icon">
              <img
                src="icons/contacticon.svg"
                width="24"
                height="24"
                alt="Contact"
              />
            </div>
            <div
              className={`side-nav-item-content ${
                isExpanded ? "expanded" : ""
              }`}
            >
              <div className="item-title">Contact</div>
              <div className="item-subtitle">Get in touch</div>
            </div>
          </a>

          {user && (
            <>
              <div className="side-nav-divider" />

              <div className="side-nav-item logout-item" onClick={signOutUser}>
                <div className="side-nav-icon">
                  <img
                    src="icons/logouticon.svg"
                    width="24"
                    height="24"
                    alt="Logout"
                  />
                </div>
                <div
                  className={`side-nav-item-content ${
                    isExpanded ? "expanded" : ""
                  }`}
                >
                  <div className="item-title">Log Out</div>
                  <div className="item-subtitle">Sign out</div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const CharacterSettingsModal = ({ character, onClose, open, auth }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedCharacter, setEditedCharacter] = useState(character);

  // Custom SweetAlert configuration with high z-index
  const swalConfig = {
    title: '',
    customClass: {
      container: 'customchat-swal-container',
      popup: 'rounded-lg',
      title: 'text-lg font-semibold',
      content: 'text-gray-600'
    },
    didOpen: () => {
      // Add a style tag for our custom container class if it doesn't exist
      if (!document.getElementById('swal-styles')) {
        const style = document.createElement('style');
        style.id = 'swal-styles';
        style.innerHTML = `
          .customchat-swal-container {
            z-index: 9999 !important;
          }
        `;
        document.head.appendChild(style);
      }
    }
  };

  const handleDeleteCharacter = async () => {
    const result = await Swal.fire({
      ...swalConfig,
      title: `Delete ${character?.name}?`,
      text: 'This action cannot be undone',
      showCancelButton: true,
      confirmButtonColor: '#D46BCB',
      cancelButtonColor: '#6b7280',
      confirmButtonText: 'Yes, delete',
      cancelButtonText: 'Cancel',
      background: 'white'
    });

    if (result.isConfirmed) {
      try {
        const authToken = await auth.currentUser.getIdToken();
        const response = await fetch(
          `${endpoint}/api/delete-character/${character?.id}`,
          {
            method: 'DELETE',
            headers: {
              Authorization: 'Bearer ' + authToken,
            },
          }
        );

        if (response.ok) {
          Swal.fire({
            ...swalConfig,
            title: 'Deleted!',
            text: `${character?.name} has been deleted`,
            confirmButtonColor: '#D46BCB',
            timer: 2000,
            showConfirmButton: false
          });
          onClose();
          window.location.reload();
        } else {
          Swal.fire({
            ...swalConfig,
            title: 'Error',
            text: 'Failed to delete character',
            confirmButtonColor: '#D46BCB',
          });
        }
      } catch (error) {
        console.error('Error deleting character:', error);
        Swal.fire({
          ...swalConfig,
          title: 'Error',
          text: 'Error deleting character',
          confirmButtonColor: '#D46BCB',
        });
      }
    }
  };

  const handleDeleteChat = async () => {
    const result = await Swal.fire({
      ...swalConfig,
      title: `Delete chat history?`,
      text: `Are you sure you want to delete your chat history with ${character?.name}?`,
      showCancelButton: true,
      confirmButtonColor: '#D46BCB',
      cancelButtonColor: '#6b7280',
      confirmButtonText: 'Yes, delete',
      cancelButtonText: 'Cancel',
      background: 'white'
    });

    if (result.isConfirmed) {
      try {
        const authToken = await auth.currentUser.getIdToken();
        const response = await fetch(
          `${endpoint}/api/delete-chat/${character?.id}`,
          {
            method: 'DELETE',
            headers: {
              Authorization: 'Bearer ' + authToken,
            },
          }
        );

        if (response.ok) {
          Swal.fire({
            ...swalConfig,
            title: 'Deleted!',
            text: 'Chat history has been deleted',
            confirmButtonColor: '#D46BCB',
            timer: 2000,
            showConfirmButton: false
          });
          onClose();
          window.location.reload();
        } else {
          Swal.fire({
            ...swalConfig,
            title: 'Error',
            text: 'Failed to delete chat history',
            confirmButtonColor: '#D46BCB',
          });
        }
      } catch (error) {
        console.error('Error deleting chat:', error);
        Swal.fire({
          ...swalConfig,
          title: 'Error',
          text: 'Error deleting chat history',
          confirmButtonColor: '#D46BCB',
        });
      }
    }
  };

  const handleSaveCharacter = async () => {
    try {
      const authToken = await auth.currentUser.getIdToken();
      const response = await fetch(
        `${endpoint}/api/update-character/${character.id}`,
        {
          method: 'PUT',
          headers: {
            Authorization: 'Bearer ' + authToken,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(editedCharacter),
        }
      );

      if (response.ok) {
        Swal.fire({
          ...swalConfig,
          title: 'Saved!',
          text: `${character?.name}'s profile has been updated`,
          confirmButtonColor: '#D46BCB',
          timer: 2000,
          showConfirmButton: false
        });
        setIsEditing(false);
        window.location.reload();
      } else {
        Swal.fire({
          ...swalConfig,
          title: 'Error',
          text: 'Failed to update character',
          confirmButtonColor: '#D46BCB',
        });
      }
    } catch (error) {
      console.error('Error updating character:', error);
      Swal.fire({
        ...swalConfig,
        title: 'Error',
        text: 'Error updating character',
        confirmButtonColor: '#D46BCB',
      });
    }
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      slotProps={{
        content: {
          sx: {
            width: {
              xs: '80vw',
              md: '30vw',
            },
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            boxSizing: 'border-box',
            overflowX: 'hidden',
            bgcolor: 'white',
            padding: '20px',
          },
        },
      }}
    >
      <div className="customchat-drawer-header" style={{ 
        padding: '30px 20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        gap: '15px'
      }}>
        <div style={{
          width: '120px',
          height: '120px',
          borderRadius: '60px',
          background: '#D46BCB',
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '48px',
          fontWeight: '500',
          overflow: 'hidden',
          marginBottom: '5px'
        }}>
          {character?.avatar_url ? (
            <img 
              src={character.avatar_url} 
              alt={character.name}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
            />
          ) : (
            character?.name?.[0].toUpperCase()
          )}
        </div>
        <div>
          <h3 style={{ 
            margin: '0',
            fontSize: '24px',
            fontWeight: '600',
            color: '#111827'
          }}>{character?.name}</h3>
          <p style={{
            margin: '8px 0 0 0',
            fontSize: '16px',
            color: '#6b7280',
            maxWidth: '280px'
          }}>{character?.shortBio}</p>
        </div>
        <div style={{
          height: '1px',
          background: '#e5e7eb',
          margin: '10px -20px 0',
          width: 'calc(100% + 40px)'
        }} />
      </div>

      <div className="customchat-drawer-content">
        {isEditing ? (
          <div className="p-4">
            <div className="mb-4">
              <input
                type="text"
                placeholder="Girl's Name"
                value={editedCharacter?.name}
                onChange={(e) =>
                  setEditedCharacter({
                    ...editedCharacter,
                    name: e.target.value,
                  })
                }
                className="customchat-input"
              />
            </div>
            <div className="mb-4">
              <input
                type="number"
                placeholder="Age"
                value={editedCharacter?.age}
                onChange={(e) =>
                  setEditedCharacter({
                    ...editedCharacter,
                    age: e.target.value,
                  })
                }
                className="customchat-input"
              />
            </div>
            <div className="mb-4">
              <textarea
                placeholder="Short Bio"
                value={editedCharacter?.shortBio}
                onChange={(e) =>
                  setEditedCharacter({
                    ...editedCharacter,
                    shortBio: e.target.value,
                  })
                }
                className="customchat-input"
                style={{ minHeight: '100px' }}
              />
            </div>
            <div className="flex gap-2">
              <button
                onClick={() => setIsEditing(false)}
                className="customchat-logout-button"
                style={{ width: '50%' }}
              >
                Cancel
              </button>
              <button
                onClick={handleSaveCharacter}
                className="customchat-new-button"
                style={{ width: '50%' }}
              >
                Save Changes
              </button>
            </div>
          </div>
        ) : (
          <div className="p-4">
            <button
              onClick={() => setIsEditing(true)}
              className="customchat-new-button mb-4"
              style={{ width: '100%' }}
            >
              Edit Character
            </button>
            <button
              onClick={handleDeleteChat}
              className="customchat-logout-button mb-4"
              style={{ width: '100%' }}
            >
              Delete Chat History
            </button>
            <button
              onClick={handleDeleteCharacter}
              className="customchat-logout-button"
              style={{ width: '100%' }}
            >
              Delete Character
            </button>
          </div>
        )}
      </div>
    </Drawer>
  );
};


const isImageUrl = (url) => {
  return url?.match(/\.(jpg|jpeg|png|gif|webp)$/i);
};

// Above CustomCharacterGenerator
const ImageViewer = React.memo(({ url, onClose }) => {
  const handleDownload = () => {
    // Create direct link and simulate click
    const link = document.createElement("a");
    link.href = url;

    // Extract filename from URL or use timestamp
    const filename = url.split("/").pop() || `image-${Date.now()}.jpg`;
    link.download = filename;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="customchat-modal" onClick={onClose}>
      <div
        className="customchat-image-viewer"
        onClick={(e) => e.stopPropagation()}
      >
        <img
          src={url}
          alt="Full size"
          style={{
            maxHeight: "90vh",
            maxWidth: "90vw",
            objectFit: "contain",
            borderRadius: "8px",
          }}
        />
        <button onClick={handleDownload} className="customchat-image-download">
          <img src="downloadicon.png" width="24px" alt="Download" />
        </button>
      </div>
    </div>
  );
});

const FeedbackModal = ({ onClose, onSubmit, user, endpoint }) => {
  const [feedBack, setFeedBack] = useState("");

  const sendFeedback = async () => {
    if (feedBack.length < 30) {
      sweety("Please think it through and write proper feedback.");
      return;
    }

    const auth = getAuth();
    const authToken = await auth.currentUser.getIdToken();

    fetch(`${endpoint}/feedback`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + authToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: user?.email, message: feedBack }),
    })
      .then((response) => {
        if (response.ok) {
          sweety("Thanks for the feedback! You got 5 free tokens.");
          onSubmit(); // This will handle fetching credits and closing modal
        } else {
          sweety(
            "Feedback submitted. No free tokens though, as you already gave feedback."
          );
        }
        return response.json();
      })
      .catch((error) => console.error("Error:", error));
  };

  return (
    <div className="backdrop" onClick={onClose}>
      <div className="modal modal3" onClick={(e) => e.stopPropagation()}>
        <div className="feedbackTitle">Submit feedback, get 5 free tokens!</div>
        <textarea
          rows={10}
          className="feedbackInput"
          value={feedBack}
          placeholder="Write your detailed feedback here. Any problems you had, your wishlist for features you'd like to use. Anything is welcome!"
          onChange={(e) => setFeedBack(e.target.value)}
        />
        <button className="feedbackButton" onClick={sendFeedback}>
          Submit feedback
        </button>
      </div>
    </div>
  );
};

// Then modify your ImageMessage component
const ImageMessage = React.memo(({ url }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isViewing, setIsViewing] = useState(false);

  return (
    <>
      <div className="customchat-image-message">
        {!imageLoaded && (
          <div className="customchat-image-loader">
            <div className="heart-loader">
              <img src="icons/hearticon.svg"></img>
            </div>
          </div>
        )}
        <img
          src={url}
          alt="Generated"
          style={{ display: imageLoaded ? "block" : "none", cursor: "pointer" }}
          onLoad={() => setImageLoaded(true)}
          onClick={() => setIsViewing(true)}
        />
      </div>
      {isViewing && (
        <ImageViewer url={url} onClose={() => setIsViewing(false)} />
      )}
    </>
  );
});

const Message = React.memo(({ message }) => {
  // New component for each message
  const isUser = message.role === "user";
  const isImage = message.role === "system" && isImageUrl(message.content);
  const isAudio = message.role === "audio";

  return (
    <div className={`customchat-message-wrapper ${isUser ? "user" : "ai"}`}>
      {isImage ? (
        <ImageMessage url={message.content} />
      ) : isAudio ? (
        <div className="customchat-audio-message">
          <audio controls src={message.content} />
        </div>
      ) : (
        <div className="customchat-text-message">{message.content}</div>
      )}
      <div className="customchat-message-time">
        {message.timestamp
          ? new Date(message.timestamp).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })
          : new Date().toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
      </div>
    </div>
  );
});

const CustomCharacterGenerator = () => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  const [user, setUser] = useState(null);
  const [characters, setCharacters] = useState([]);
  const [showCreator, setShowCreator] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [newCharacter, setNewCharacter] = useState({
    name: "",
    age: "",
    shortBio: "",
    personality: "",
    ethnicity: "", // Changed from faceShape
    buildType: "",
    hairColor: "",
  });
  const [loading, setLoading] = useState(false);
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [error, setError] = useState("");
  const [messageLoading, setMessageLoading] = useState(false);
  const bottomRef = useRef(null);
  const [language, setLanguage] = useState("english");
  const [open, setOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [credits, setCredits] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (user) {
      fetchCredits(user?.email);
    }
  }, [user]);

  const handlePayment = (priceId) => {
    // This will determine which Stripe function to call based on subscription status
    isSubscribed 
      ? paymentWithStripe(priceId)
      : subscriptionWithStripe(priceId, user, endpoint, sweety);
  };

  const fetchCredits = async (userEmail) => {
    try {
      const response = await fetch(`${endpoint}/read-credits`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: userEmail }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.status === 200 && data.error_code === 0) {
        setIsSubscribed(data?.is_subscribed);
        setCredits(data.token_balance);
      } else {
        console.error("Error fetching credits:", data.message);
        return null;
      }
    } catch (error) {
      console.error("There was a problem fetching user credits:", error);
    }
  };

  const openPaymentModal = () => {
    setOpen(false)
    setIsPaymentModalOpen(true);
  };
  const toggleDrawer = (inOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(inOpen);
  };

  const generateRandomPersonality = () => {
    const randomIndex = Math.floor(Math.random() * personalityTemplates.length);
    setNewCharacter((prev) => ({
      ...prev,
      personality: personalityTemplates[randomIndex],
    }));
  };
  const subscriptionWithStripe = async (price_id, user, endpoint, sweety) => {
    const stripe = await loadStripe(
      "pk_live_51OMTabEsKxxKHfJyzJZA51h82xgLRz3YxS1B8CWWRaMPDl9ta5x8PRQNCAebuSouKr1bYOKEU2LgE9t4nc1R49mY00qqxFba3v"
    );

    const fbclid = localStorage.getItem('fbclid');
    const userAgent = localStorage.getItem('userAgent');

    fetch(`${endpoint}/create-subscription-checkout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tolt_referral: window.tolt_referral,
        email: user?.email, 
        price_id: price_id,
        fbclid: fbclid,
        user_agent: userAgent
      })
    })
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((session) => {
        if (session.id) {
          stripe.redirectToCheckout({ sessionId: session.id });
        } else {
          throw new Error("Session ID is undefined.");
        }
      })
      .catch((error) => {
        sweety("There was an error processing your request. Please try again.");
      });
  };

  const paymentWithStripe = async (price_id) => {
    const stripe = await loadStripe(
      "pk_live_51OMTabEsKxxKHfJyzJZA51h82xgLRz3YxS1B8CWWRaMPDl9ta5x8PRQNCAebuSouKr1bYOKEU2LgE9t4nc1R49mY00qqxFba3v"
    );

    fetch(`${endpoint}/create-checkout-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tolt_referral: window.tolt_referral,
        email: user?.email,
        price_id: price_id,
      }), // Make sure this matches your server-side logic
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((session) => {
        // Debugging: Log the session ID to make sure it's received correctly
        console.log("Session ID:", session.id);

        if (session.id) {
          stripe.redirectToCheckout({ sessionId: session.id });
        } else {
          throw new Error("Session ID is undefined.");
        }
      })
      .catch((error) => console.error("Error:", error));
  };
  const nextStep = () => {
    if (currentStep === 1) {
      if (!newCharacter.name || !newCharacter.age || !newCharacter.ethnicity) {
        sweety("Please fill in all fields and select an ethnicity");
        return;
      }
      const age = parseInt(newCharacter.age);
      if (isNaN(age) || age < 18) {
        sweety("Age must be 18 or above. Come on.");
        return;
      }
    }
    if (currentStep === 2) {
      if (!newCharacter.buildType || !newCharacter.hairColor) {
        sweety("Please select all appearance options");
        return;
      }
    }
    setCurrentStep((prev) => Math.min(prev + 1, 3));
  };

  const prevStep = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 1));
  };

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "auto" }); // Changed from smooth to auto
    }
  }, [messages]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user) {
        fetchCharacters().then(() => {
          // Move this logic into fetchCharacters' success case
          if (characters.length > 0 && !selectedCharacter) {
            selectCharacter(characters[0]);
          }
        });
      }
    });
    return () => unsubscribe();
  }, []);

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error("Error signing in with Google:", error);
      setError("Failed to sign in. Please try again.");
    }
  };

  const signOutUser = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setCharacters([]);
      setMessages([]);
      setSelectedCharacter(null);
    } catch (error) {
      console.error("Error signing out:", error);
      setError("Failed to sign out. Please try again.");
    }
  };

  const fetchCharacters = async () => {
    try {
      const authToken = await auth.currentUser.getIdToken();
      const response = await fetch(`${endpoint}/api/get-custom-characters`, {
        headers: { Authorization: "Bearer " + authToken },
      });
      if (response.ok) {
        const data = await response.json();
        setCharacters(data.characters);
        // If we don't have a selected character yet, select the first one
        if (data.characters.length > 0 && !selectedCharacter) {
          selectCharacter(data.characters[0]);
        }
      } else {
        throw new Error("Failed to load characters");
      }
    } catch (error) {
      console.error("Error fetching characters:", error);
      setError("Failed to load characters. Please try again later.");
    }
  };

  const fetchMessages = async (characterId) => {
    try {
      const authToken = await auth.currentUser.getIdToken();
      const response = await fetch(
        `${endpoint}/api/get-chat-history/${characterId}`,
        {
          headers: {
            Authorization: "Bearer " + authToken,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setMessages(data.messages || []);
      } else {
        throw new Error("Failed to fetch messages");
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
      setError("Failed to load messages. Please try again.");
    }
  };

  const selectCharacter = (character) => {
    setSelectedCharacter(character);
    fetchMessages(character.id);
  };

  const images = [
    "createany/1.png",
    "createany/2.png",
    "createany/3.png",
    "createany/4.png",
  ];

  const [currentImage, setCurrentImage] = useState(0);

  // Add this useEffect to handle the image rotation
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImage((current) =>
        current === images.length - 1 ? 0 : current + 1
      );
    }, 1500); // Change image every 3 seconds

    return () => clearInterval(timer);
  }, []);
  

  const handleCreateCharacter = async () => {
    if (!newCharacter.shortBio || !newCharacter.shortBio.trim()) {
      sweety("Please enter character short bio");
      return;
    }

    if (!newCharacter.personality || !newCharacter.personality.trim()) {
      sweety("Please enter or generate a personality");
      return;
    }

    if (credits < 10) {
      sweety("You need 10 tokens to create your own girl! You're missing " + (10 - credits) + " tokens.");
      setIsPaymentModalOpen(true);
      setShowCreator(false); // Close the create modal

      return;
    }

    setLoading(true);
    setError("");
    try {
      const authToken = await auth.currentUser.getIdToken();
      const response = await fetch(`${endpoint}/api/create-character`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + authToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...newCharacter,
          language,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setCharacters((prev) => [...prev, data]);
        setSelectedCharacter(data); // Select the new character
        fetchMessages(data.id); // Load its messages
        setNewCharacter({
          name: "",
          age: "",
          shortBio: "",
          personality: "",
          faceShape: "",
          buildType: "",
          hairColor: "",
        });
        setLanguage("english");
        setCurrentStep(1);
        setShowCreator(false);
      } else {
        const errorData = await response.json();
        sweety(errorData.error || "Failed to create character");
      }
    } catch (error) {
      console.error("Error creating character:", error);
      sweety("Failed to create character. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSendMessage = async () => {
    if (!newMessage.trim() || !selectedCharacter || messageLoading) return;

    if (credits < 1) {
      sweety("You need more tokens to continue chatting!");
      setIsPaymentModalOpen(true);
      return;
    }

    const messageObj = {
      role: "user",
      content: newMessage,
      timestamp: new Date().toISOString(),
    };
    setMessages((prev) => [...prev, messageObj]);
    setNewMessage("");
    setMessageLoading(true);

    try {
      const authToken = await auth.currentUser.getIdToken();
      const response = await fetch(`${endpoint}/api/chat-with-character`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + authToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message: newMessage,
          characterId: selectedCharacter.id,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setCredits(prevCredits => prevCredits - 1);
        setMessages((prev) => [
          ...prev,
          {
            ...data,
            timestamp: new Date().toISOString(),
          },
        ]);
      } else {
        throw new Error("Failed to send message");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setError("Failed to send message. Please try again.");
      setMessages((prev) => prev.slice(0, -1));
    } finally {
      setMessageLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  // Then in your messages render:
  <div className="customchat-messages">
    {messages.map((message, index) => (
      <Message key={`message-${index}`} message={message} />
    ))}
    {messageLoading && (
      <div className="customchat-message-wrapper ai">
        <div className="customchat-typing-indicator">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    )}
    <div ref={bottomRef} />
  </div>;

const renderCreatorStep = () => {
  switch (currentStep) {
    case 1:
      return (
        <div className="customchat-step-content">
          <div className="customchat-appearance-section">
            <label className="customchat-label">Ethnicity</label>
            <div className="face-shape-group">
              {ethnicities.map((ethnic) => (
                <div
                  key={ethnic.id}
                  onClick={() =>
                    setNewCharacter({ ...newCharacter, ethnicity: ethnic.id })
                  }
                  className={`face-shape-option ${
                    newCharacter.ethnicity === ethnic.id ? "active" : ""
                  }`}
                >
                  <img src={`${ethnic.img}`} alt={ethnic.label} />
                  <span>{ethnic.label}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="customchat-appearance-section">
            <label className="customchat-label">
              What language does she speak?
            </label>
            <div className="toggle-group">
              <button
                onClick={() => setLanguage("english")}
                className={`toggle-button ${
                  language === "english" ? "active" : ""
                }`}
              >
                English
              </button>
              <button
                onClick={() => setLanguage("russian")}
                className={`toggle-button ${
                  language === "russian" ? "active" : ""
                }`}
              >
                Russian
              </button>
              <button
                onClick={() => setLanguage("spanish")}
                className={`toggle-button ${
                  language === "spanish" ? "active" : ""
                }`}
              >
                Spanish
              </button>
            </div>
          </div>

          <input
            type="text"
            placeholder="Girl's Name"
            value={newCharacter.name}
            onChange={(e) =>
              setNewCharacter({ ...newCharacter, name: e.target.value })
            }
            className="customchat-input"
          />
          <input
            type="number"
            placeholder="Age"
            value={newCharacter.age}
            onChange={(e) =>
              setNewCharacter({ ...newCharacter, age: e.target.value })
            }
            className="customchat-input"
          />
        </div>
      );
    case 2:
      return (
        <div className="customchat-step-content">
          <div className="customchat-appearance-section">
            <label className="customchat-label">Build Type</label>
            <div className="toggle-group">
              {buildTypes.map((build) => (
                <button
                  key={build.id}
                  onClick={() =>
                    setNewCharacter({ ...newCharacter, buildType: build.id })
                  }
                  className={`toggle-button ${
                    newCharacter.buildType === build.id ? "active" : ""
                  }`}
                >
                  {build.label}
                </button>
              ))}
            </div>
          </div>

          <div className="customchat-appearance-section">
            <label className="customchat-label">Hair Color</label>
            <div className="toggle-group">
              {hairColors.map((hair) => (
                <button
                  key={hair.id}
                  onClick={() =>
                    setNewCharacter({ ...newCharacter, hairColor: hair.id })
                  }
                  className={`toggle-button ${
                    newCharacter.hairColor === hair.id ? "active" : ""
                  }`}
                >
                  <span
                    className="color-dot"
                    style={{ backgroundColor: hair.color }}
                  />
                  {hair.label}
                </button>
              ))}
            </div>
          </div>
        </div>
      );
    case 3:
      return (
        <div className="customchat-step-content">
          <div className="customchat-input-group">
            <input
              type="text"
              placeholder="Short Bio (max 30 characters)"
              value={newCharacter.shortBio}
              onChange={(e) => {
                if (e.target.value.length <= 30) {
                  setNewCharacter({
                    ...newCharacter,
                    shortBio: e.target.value,
                  });
                }
              }}
              className="customchat-input"
            />
            <span className="customchat-char-counter">
              {newCharacter.shortBio.length}/30
            </span>
          </div>

          <div className="customchat-personality-section">
            <textarea
              placeholder="Your girl's personality description..."
              value={newCharacter.personality}
              onChange={(e) =>
                setNewCharacter({
                  ...newCharacter,
                  personality: e.target.value,
                })
              }
              className="customchat-textarea"
            />
            <button
              onClick={generateRandomPersonality}
              className="customchat-generate-button"
            >
              Generate personality for me
            </button>
          </div>
        </div>
      );
    default:
      return null;
  }
};

  if (!user) {
    return (
      <div className="customchat-login">
        <div className="customchat-login-container">
          <div className="customchat-login-content">
            <h1 className="customchat-login-title">
              Create your <br />
              <span>dream girl</span>
            </h1>
            <p className="customchat-login-subtitle">
              Young? Blonde? Thick? Speaks to you in Spanish?
              <p>You're in control.</p>
            </p>
            <button
              className="customchat-login-button"
              onClick={() => setIsSignupModalOpen(true)}
            >
              Sign in to start chatting
            </button>
          </div>
          <div className="customchat-login-carousel">
            <div className="customchat-login-header">
              <img src="icon.png" alt="Logo" width="40" height="40" />
              <span className="customchat-login-logo-text">Lollipop</span>
            </div>
            {images.map((img, index) => (
              <div
                key={index}
                className="customchat-carousel-slide"
                style={{
                  backgroundImage: `url(${img})`,
                  opacity: currentImage === index ? 1 : 0,
                  transition: "opacity 0.5s ease-in-out",
                }}
              />
            ))}
            <div className="customchat-carousel-overlay" />
          </div>
        </div>
        {isSignupModalOpen && (
          <SignupModal
            onClose={() => setIsSignupModalOpen(false)}
            onSubmit={handleSignupSubmit}
          />
        )}
      </div>
    );
  }
  

  return (
    <div className="customchat-container">
      {/* Sidebar */}
      <SideNav
        user={user}
        credits={credits} // Make sure this is passed
        openPaymentModal={openPaymentModal}
        setIsSignupModalOpen={setIsSignupModalOpen}
        setIsFeedbackModalOpen={setIsFeedbackModalOpen}
        signOutUser={signOutUser}
      />
      <div className="customchat-sidebar">
        <div className="customchat-character-list">
          <div className="customchat-character-items">
            {characters?.length > 1 ? (
              characters.map((character) => (
                <div
                  key={character.id}
                  onClick={() => selectCharacter(character)}
                  className={`customchat-character-item ${
                    selectedCharacter?.id === character.id ? "selected" : ""
                  }`}
                >
                  <div className="customchat-character-avatar">
                    {character.avatar_url ? (
                      <img src={character.avatar_url} alt={character.name} />
                    ) : (
                      character.name[0].toUpperCase()
                    )}
                  </div>
                  <div className="customchat-character-info">
                    <h3>{character.name}</h3>
                    <p>{character?.shortBio}</p>
                  </div>
                </div>
              ))
            ) : (
              <div className="customchat-nogirls">
                You haven't created any girls.
              </div>
            )}
          </div>
          <div className="customchat-user-header">
            <button
              className="customchat-new-button"
              style={{ width: "100%" }}
              onClick={() => setShowCreator(true)}
            >
              Create new girl
            </button>
          </div>
        </div>
      </div>

      {/* Main Chat Area */}
      <div className="customchat-main">
        <div className="customchat-chat-header">
          <div className="customchat-chat-title">
            {selectedCharacter ? (
              <img
                src={selectedCharacter?.avatar_url}
                className="customchat-character-avatar"
              />
            ) : (
              <></>
            )}
            <div>
              <div style={{ fontWeight: "bold" }}>
                {selectedCharacter?.name}
              </div>
              <div className="customchat-chat-title-desc">
                {selectedCharacter?.age && (
                  <span>{selectedCharacter?.shortBio}</span>
                )}
              </div>
            </div>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            {selectedCharacter && (
              <div
                onClick={() => setSettingsOpen(true)}
                style={{ border: "none" }}
                className="customchat-menu-button"
              >
                <img src="icons/settingsicon.svg" />
              </div>
            )}
            <button
              onClick={toggleDrawer(true)}
              className="customchat-menu-button mobile-only"
            >
              <span>My girls</span>
            </button>
          </div>
          {isSignupModalOpen && (
            <SignupModal
              onClose={() => setIsSignupModalOpen(false)}
              onSubmit={handleSignupSubmit}
            />
          )}

          {selectedCharacter && (
            <CharacterSettingsModal
              character={selectedCharacter}
              open={settingsOpen}
              onClose={() => setSettingsOpen(false)}
              auth={auth}
            />
          )}
        </div>
        {selectedCharacter ? (
          <>
            <div className="customchat-messages">
              {messages.map((message, index) => (
                <Message key={`message-${index}`} message={message} />
              ))}
              {messageLoading && (
                <div className="customchat-message-wrapper ai">
                  <div className="customchat-typing-indicator">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              )}
              <div ref={bottomRef} />
            </div>
            <div className="customchat-input-area">
              <input
                placeholder="Type a message..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyPress={handleKeyPress}
                disabled={messageLoading}
                className="customchat-input"
              />
              <button
                onClick={handleSendMessage}
                disabled={messageLoading || !newMessage.trim()}
                className="customchat-send-button"
              >
                Send
              </button>
            </div>
          </>
        ) : (
          <div className="customchat-empty-state">
            <div className="customchat-empty-content">
              <h2>Select a girl to start chatting</h2>
              <p>Or create a new one to begin your conversation</p>
              <button
                className="customchat-create-button"
                onClick={() => setShowCreator(true)}
              >
                Create new girl
              </button>
            </div>
          </div>
        )}
      </div>

      {isPaymentModalOpen && (
        <NewPaymentModal
          onClose={() => setIsPaymentModalOpen(false)}
          isSubscribed={isSubscribed}
          handlePayment={handlePayment}
        />
      )}
      {isFeedbackModalOpen && (
        <FeedbackModal
          onClose={() => setIsFeedbackModalOpen(false)}
          onSubmit={() => {
            setIsFeedbackModalOpen(false);
            fetchCredits(user?.email);
          }}
          user={user}
          endpoint={endpoint}
        />
      )}

      {/* Character Creator Modal */}
      {showCreator && (
        <div
          className="customchat-modal"
          onClick={() => !loading && setShowCreator(false)}
        >
          <div
            className="customchat-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="customchat-progress-dots">
              {[1, 2, 3].map((step) => (
                <div
                  key={step}
                  className={`progress-dot ${
                    currentStep >= step ? "active" : ""
                  }`}
                />
              ))}
            </div>

            <div className="customchat-modal-body">{renderCreatorStep()}</div>

            <div className="customchat-modal-footer">
              {currentStep > 1 && (
                <button
                  onClick={prevStep}
                  disabled={loading}
                  className="customchat-button secondary"
                >
                  Back
                </button>
              )}
              {currentStep < 3 ? (
                <button
                  onClick={nextStep}
                  disabled={loading}
                  className="customchat-create-button"
                >
                  Next
                </button>
              ) : (
                <button
                  onClick={handleCreateCharacter}
                  disabled={loading}
                  className="customchat-create-button"
                >
                  {loading ? "Creating..." : "Create girl (10 tokens)"}
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Character Info Modal */}
      {showInfo && selectedCharacter && (
        <div className="customchat-modal" onClick={() => setShowInfo(false)}>
          <div
            className="customchat-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="customchat-modal-header">
              <h2>{selectedCharacter.name}</h2>
              <button onClick={() => setShowInfo(false)}>×</button>
            </div>
            <div className="customchat-modal-body">
              <div className="customchat-info-grid">
                <div className="customchat-info-item">
                  <label>Age</label>
                  <span>{selectedCharacter.age}</span>
                </div>
                <div className="customchat-info-item">
                  <label>Description</label>
                  <p>{selectedCharacter.shortDescription}</p>
                </div>
                <div className="customchat-info-item">
                  <label>Personality</label>
                  <p>{selectedCharacter.personality}</p>
                </div>
                <div className="customchat-info-item">
                  <label>Background</label>
                  <p>{selectedCharacter.background}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Drawer
        open={open}
        onClose={toggleDrawer(false)}
        slotProps={{
          content: {
            sx: {
              width: "80vw",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              boxSizing: "border-box",
              overflowX: "hidden",
            },
          },
        }}
      >
        <div className="customchat-drawer-header">
          <h3>Your girls</h3>
        </div>
        <div className="customchat-drawer-content">
          {characters?.length > 0 ? (
            characters.map((character) => (
              <div
                key={character.id}
                onClick={() => {
                  selectCharacter(character);
                  setOpen(false);
                }}
                className={`customchat-character-item ${
                  selectedCharacter?.id === character.id ? "selected" : ""
                }`}
              >
                <div className="customchat-character-avatar">
                  {character.avatar_url ? (
                    <img src={character.avatar_url} alt={character.name} />
                  ) : (
                    character.name[0].toUpperCase()
                  )}
                </div>
                <div className="customchat-character-info">
                  <h3>{character.name}</h3>
                  {character?.shortBio && <p>{character?.shortBio}</p>}
                </div>
              </div>
            ))
          ) : (
            <div className="customchat-nogirls">
              You haven't created any girls.
            </div>
          )}
        </div>
        {/* Add the menu items at the bottom */}
        <div className="customchat-drawer-menu">
          {/* Base grid - always visible */}
          <div className="customchat-drawer-menu-grid">
            {user ? (
              <>

                <div
                  className="customchat-drawer-menu-item"
                  onClick={openPaymentModal}
                >
                  <div className="customchat-drawer-menu-label">Credits</div>
                  <div className="customchat-drawer-menu-value">
                    {credits > 0 ? credits : "Buy"}
                  </div>
                </div>

                <div
                  className="customchat-drawer-menu-item"
                  onClick={signOutUser}
                >
                  <div className="customchat-drawer-menu-label">Account</div>
                  <div className="customchat-drawer-menu-value">Log Out</div>
                </div>

                <div
                  className="customchat-drawer-menu-item customchat-drawer-menu-create"
                  style={{ width: "100%" }}
                  onClick={() => {
                    setShowCreator(true);
                    setOpen(false);
                  }}
                >
                  <div className="customchat-drawer-menu-label" style={{color:"white"}}>Create</div>
                  <div className="customchat-drawer-menu-value" style={{color:"white"}}>
                    Your own girl
                  </div>
                </div>
              </>
            ) : (
              <div
                className="customchat-drawer-menu-item customchat-drawer-menu-item-full"
                onClick={() => setIsSignupModalOpen(true)}
              >
                <div className="customchat-drawer-menu-label">
                  Talk to girls
                </div>
                <div className="customchat-drawer-menu-value">Log In</div>
              </div>
            )}
          </div>

          {/* Expandable section - no extra wrapper div */}
          <div
            className={`customchat-drawer-menu-expanded ${
              isExpanded ? "show" : ""
            }`}
          >
            {user ? (
              <>
                <a href="/" className="customchat-drawer-menu-item">
                  <div className="customchat-drawer-menu-label">Talk to</div>
                  <div className="customchat-drawer-menu-value">
                    Lollipop girls
                  </div>
                </a>

                <div
                  className="customchat-drawer-menu-item"
                  onClick={() =>
                    window.open("https://lollipop.chat/partners", "_blank")
                  }
                >
                  <div className="customchat-drawer-menu-label">Partners</div>
                  <div className="customchat-drawer-menu-value">Explore</div>
                </div>

                <a
                  href="mailto:lenny@lollipop.chat"
                  className="customchat-drawer-menu-item"
                >
                  <div className="customchat-drawer-menu-label">Contact</div>
                  <div className="customchat-drawer-menu-value">
                    Get in touch
                  </div>
                </a>

                <div
                  className="customchat-drawer-menu-item"
                  onClick={() => {setIsFeedbackModalOpen(true)
                  setOpen(false)
                  }}
                >
                  <div className="customchat-drawer-menu-label">Feedback</div>
                  <div className="customchat-drawer-menu-value">Get tokens</div>
                </div>

                <a href="/generate-image" className="customchat-drawer-menu-item">
 <div className="customchat-drawer-menu-label">Generate</div>
 <div className="customchat-drawer-menu-value">Photos</div>
</a>

<a 
 href="https://billing.stripe.com/p/login/6oE7sC4UZ6DLcUg5kk"
 target="_blank"
 rel="noopener noreferrer"
 className="customchat-drawer-menu-item"
>
 <div className="customchat-drawer-menu-label">Manage</div>
 <div className="customchat-drawer-menu-value">Subscription</div>
</a>
              </>
            ) : (
              <>
                <a href="/generate-image" className="customchat-drawer-menu-item">
                  <div className="customchat-drawer-menu-label">Generate</div>
                  <div className="customchat-drawer-menu-value">Photos</div>
                </a>

                <div
                  className="customchat-drawer-menu-item"
                  onClick={() =>
                    window.open("https://lollipop.chat/affiliate", "_blank")
                  }
                >
                  <div className="customchat-drawer-menu-label">Partners</div>
                  <div className="customchat-drawer-menu-value">Explore</div>
                </div>

                <a
                  href="mailto:lenny@lollipop.chat"
                  className="customchat-drawer-menu-item"
                  style={{ gridColumn: "span 2" }}
                >
                  <div className="customchat-drawer-menu-label">Contact</div>
                  <div className="customchat-drawer-menu-value">
                    Get in touch
                  </div>
                </a>
              </>
            )}
          </div>

          {/* More/Less button */}
          <div className="customchat-drawer-menu-grid">
            <div
              className="customchat-drawer-menu-item customchat-drawer-menu-more"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <div className="customchat-drawer-menu-label">
                {isExpanded ? "Show Less" : "More"}
              </div>
              <div className="customchat-drawer-menu-value">
                {isExpanded ? "Collapse" : "Options"}
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default CustomCharacterGenerator;
