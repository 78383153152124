import React, { useState, useEffect } from 'react';
import { Check, Volume2 } from 'lucide-react';
import './MultiStepSelector.css';
import { Link } from 'react-router-dom';

const Slideshow = ({ images, interval = 3000 }) => {
  const [indexes, setIndexes] = useState({ 
    current: 0,
    next: 1
  });
  const [transitioning, setTransitioning] = useState(false);

  useEffect(() => {
    const mainTimer = setInterval(() => {
      if (!transitioning) {
        setTransitioning(true);
        
        setTimeout(() => {
          setIndexes(prev => ({
            current: prev.next,
            next: (prev.next + 1) % images.length
          }));
          setTransitioning(false);
        }, 200);
      }
    }, interval);

    return () => clearInterval(mainTimer);
  }, [images.length, interval]);

  return (
    <div className="makeyourown-slideshow-container">
      <img
        key={`img-${indexes.current}`}
        src={images[indexes.current]}
        alt={`Slide ${indexes.current + 1}`}
        className="makeyourown-slideshow-image"
        style={{ opacity: transitioning ? 0 : 1 }}
      />
      <img
        key={`img-${indexes.next}`}
        src={images[indexes.next]}
        alt={`Slide ${indexes.next + 1}`}
        className="makeyourown-slideshow-image"
        style={{ opacity: transitioning ? 1 : 0 }}
      />
    </div>
  );
};

const LoadingScreen = () => (
  <div className="makeyourown-loading-screen">
    <div className="makeyourown-loading-spinner">
      <div className="makeyourown-spinner-circle"></div>
      <div className="makeyourown-spinner-circle"></div>
      <div className="makeyourown-spinner-circle"></div>
    </div>
    <div className="makeyourown-loading-text">Creating your perfect match...</div>
  </div>
);

const AudioSample = ({ src, label }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = React.useRef(null);

  const handlePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleEnded = () => {
    setIsPlaying(false);
  };

  return (
    <div className="makeyourown-audio-sample">
      <button 
        className={`makeyourown-audio-button ${isPlaying ? 'playing' : ''}`}
        onClick={handlePlay}
      >
        <Volume2 size={18} />
        <span>Listen to her</span>
      </button>
      <audio 
        ref={audioRef}
        src={src}
        onEnded={handleEnded}
      />
    </div>
  );
};

const MultiStepSelector = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [selections, setSelections] = useState({
    step1: null,
    step2: null,
    bodyType: null,
    hairColor: null,
    language: null,
    name: '',
    age: '',
    shortBio: '',
    personality: ''
  });

  const profiles = [
    {
      name: 'Anna',
      age: '25',
      shortBio: 'Hairstylist from LA',
      personality: 'Fun and outgoing, loves to make people feel beautiful'
    },
    {
      name: 'Sophie',
      age: '23',
      shortBio: 'Art student with big dreams',
      personality: 'Creative soul with a passion for colors and design'
    },
    {
      name: 'Emma',
      age: '27',
      shortBio: 'Yoga instructor & travel lover',
      personality: 'Peaceful and mindful, always seeking new adventures'
    },
    {
      name: 'Lucy',
      age: '24',
      shortBio: 'Coffee shop owner',
      personality: 'Warm and friendly, makes the best lattes in town'
    }
  ];

  const languageOptions = [
    {
      name: 'English',
      sample: './audio/english-sample.mp3',
      description: 'American girl'
    },
    {
      name: 'Spanish',
      sample: './audio/spanish-sample.mp3',
      description: '¿Charlamos?'
    },
    {
      name: 'Russian',
      sample: './audio/russian-sample.mp3',
      description: 'Давайте поболтаем'
    }
  ];

  const handleAutofill = () => {
    const randomProfile = profiles[Math.floor(Math.random() * profiles.length)];
    setSelections(prev => ({
      ...prev,
      ...randomProfile
    }));
  };

  const autoAdvanceSteps = new Set([1, 2]);

  const handleSelection = (field, value) => {
    setSelections(prev => ({
      ...prev,
      [field]: value
    }));
    
    if (autoAdvanceSteps.has(currentStep) && currentStep < 6) {
      setTimeout(() => {
        setCurrentStep(prev => prev + 1);
      }, 300);
    }
  };

  const nextStep = () => {
    if (currentStep === 5) {
      alert("LOGIN POPUP HERE.")
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        setCurrentStep(prev => prev + 1);
      }, 3000);
    } else if(currentStep === 6){
      alert("Payment here or allow chat for a bit?")
    }
    else if (currentStep < 6) {
      setCurrentStep(prev => prev + 1);
    }
  };

  const previousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(prev => prev - 1);
    }
  };

  const lollipopImages = [
    './anna1.png',
    './anna2.png',
    './anna3.png'
  ];

  const customGirlImages = [
    './createany/1.png',
    './createany/2.png',
    './createany/3.png',
    './createany/4.png',
  ];

  const isStep3Valid = selections.bodyType && selections.hairColor;
  const isStep4Valid = selections.language;
  const isStep5Valid = selections.name && selections.age && 
                      selections.shortBio && selections.personality;

  const renderStep = () => {
    if (isLoading) {
      return <LoadingScreen />;
    }

    switch (currentStep) {
      case 1:
        return (
          <div className="makeyourown-step-container">
            <h1 className="makeyourown-title">Meet an AI girl</h1>
            <div className="makeyourown-subtitle">with chat, voice and photos.</div>
            <div className="makeyourown-grid makeyourown-grid-step1">
              <div className="makeyourown-card-wrapper">
                <Link 
                  className={`makeyourown-card ${selections.step1 === 'minimal' ? 'selected' : ''}`}
                  to="/"
                >
                  <div className="makeyourown-card-image">
                    <Slideshow images={lollipopImages} interval={3000} />
                    <div className="makeyourown-firsttag">Meet <br/>Lollipop girls</div>
                  </div>
                </Link>
              </div>
              <div className="makeyourown-card-wrapper">
                <div 
                  className={`makeyourown-card ${selections.step1 === 'bold' ? 'selected' : ''}`}
                  onClick={() => handleSelection('step1', 'bold')}
                >
                  <div className="makeyourown-card-image">
                    <Slideshow images={customGirlImages} interval={1500} />
                    <span className="makeyourown-firsttag">Make your girl</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      case 2:
        return (
          <div className="makeyourown-step-container">
            <h2 className="makeyourown-subtitle">What ethnicity is she?</h2>
            <div className="makeyourown-grid makeyourown-grid-step2">
              {[
                { name: 'Element 1', tag: 'European', img: './customgirls/european.png' },
                { name: 'Element 2', tag: 'Latina', img: './customgirls/latina.png' },
                { name: 'Element 3', tag: 'Asian', img: './customgirls/asian.png' },
                { name: 'Element 4', tag: 'African', img: './customgirls/african.png' },
                { name: 'Element 5', tag: 'Middle-eastern', img: './customgirls/middle_eastern.png' },
                { name: 'Element 6', tag: 'Indian', img: './customgirls/indian.png' }
              ].map((element) => (
                <div 
                  key={element.name}
                  className={`makeyourown-card ${selections.step2 === element.name ? 'selected' : ''}`}
                  onClick={() => handleSelection('step2', element.name)}
                >
                  <div className="makeyourown-card-image">
                    <img src={element.img} alt={element.name} />
                    <span className="makeyourown-tag">{element.tag}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );

      case 3:
        return (
          <div className="makeyourown-step-container">
            <h2 className="makeyourown-subtitle">What does she look like?</h2>
            <div className="makeyourown-features-container">
              <div className="makeyourown-feature-section">
                <h3 className="makeyourown-feature-title">Body Type</h3>
                <div className="makeyourown-feature-buttons">
                  {['Skinny', 'Fit', 'Big'].map((type) => (
                    <button
                      key={type}
                      className={`makeyourown-feature-button ${
                        selections.bodyType === type.toLowerCase() ? 'selected' : ''
                      }`}
                      onClick={() => handleSelection('bodyType', type.toLowerCase())}
                    >
                      {type}
                    </button>
                  ))}
                </div>
              </div>
              
              <div className="makeyourown-feature-section">
                <h3 className="makeyourown-feature-title">Hair Color</h3>
                <div className="makeyourown-feature-buttons">
                  {['Blonde', 'Brunette', 'Ginger'].map((color) => (
                    <button
                      key={color}
                      className={`makeyourown-feature-button ${
                        selections.hairColor === color.toLowerCase() ? 'selected' : ''
                      }`}
                      onClick={() => handleSelection('hairColor', color.toLowerCase())}
                    >
                      {color}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        );

      case 4:
        return (
          <div className="makeyourown-step-container">
            <h2 className="makeyourown-subtitle">Choose her language</h2>
            <div className="makeyourown-language-container">
              {languageOptions.map((lang) => (
                <div 
                  key={lang.name}
                  className={`makeyourown-language-card ${
                    selections.language === lang.name.toLowerCase() ? 'selected' : ''
                  }`}
                  onClick={() => handleSelection('language', lang.name.toLowerCase())}
                >
                  <div className="makeyourown-language-content">
                    <h3 className="makeyourown-language-name">{lang.name}</h3>
                    <p className="makeyourown-language-description">{lang.description}</p>
                    <AudioSample src={lang.sample} label={lang.name} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        );

      case 5:
        return (
          <div className="makeyourown-step-container">
            <h2 className="makeyourown-subtitle">Who is she?</h2>
            <div className="makeyourown-form">
              <button 
                className="makeyourown-autofill-button"
                onClick={handleAutofill}
                type="button"
              >
                Autofill for me
              </button>
              <div className="makeyourown-input-group">
                <input
                  type="text"
                  placeholder="Name"
                  value={selections.name}
                  onChange={(e) => handleSelection('name', e.target.value)}
                  className="makeyourown-input"
                />
                <input
                  type="number"
                  placeholder="Age"
                  value={selections.age}
                  onChange={(e) => handleSelection('age', e.target.value)}
                  className="makeyourown-input"
                  min="18"
                  max="65"
                />
              </div>
              <input
                type="text"
                placeholder="Short bio (30 characters max)"
                value={selections.shortBio}
                onChange={(e) => handleSelection('shortBio', e.target.value.slice(0, 30))}
                className="makeyourown-input"
                maxLength={30}
              />
              <textarea
                placeholder="Personality description..."
                value={selections.personality}
                onChange={(e) => handleSelection('personality', e.target.value)}
                className="makeyourown-textarea"
                rows={4}
              />
            </div>
          </div>
        );

      case 6:
        return (
          <div className="makeyourown-step-container">
            <div className="makeyourown-shesready">She's ready for you!</div>
            <div className="makeyourown-final-card">
              <div className="makeyourown-final-image">
                <img src="./anna2.png" alt="Generated character" />
              </div>
              <div className="makeyourown-final-overlay">
                <div className="makeyourown-final-content">
                  <h2 className="makeyourown-final-name">
                    {selections.name}, {selections.age}
                  </h2>
                  <p className="makeyourown-final-bio">{selections.shortBio}</p>
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="makeyourown-container">
      <div className="makeyourown-wrapper">
        {currentStep !== 1 && (
        <div className="makeyourown-progress">
          <div 
            className="makeyourown-progress-bar" 
            style={{ width: `${((currentStep - 1) / 5) * 100}%` }}
          />
          {[1, 2, 3, 4, 5, 6].map((step) => (
            <div
              key={step}
              className={`makeyourown-step ${
                step === currentStep ? 'active' : step < currentStep ? 'completed' : ''
              }`}
            >
              {step < currentStep ? <Check /> : step}
            </div>
          ))}
        </div>
        )}

        <div className="makeyourown-content">
          {renderStep()}
          
          {!isLoading && (
            <div className="makeyourown-buttons">
              {(currentStep > 1 && currentStep !== 6) && (
                <button 
                  className="makeyourown-button makeyourown-button-secondary"
                  onClick={previousStep}
                >
                  Previous
                </button>
              )}
              {(!autoAdvanceSteps.has(currentStep) || currentStep === 6) && (
                <button 
                  className="makeyourown-button"
                  onClick={nextStep}
                  disabled={
                    (currentStep === 3 && !isStep3Valid) ||
                    (currentStep === 4 && !isStep4Valid) ||
                    (currentStep === 5 && !isStep5Valid)
                  }
                >
                  {currentStep === 6 ? 'Meet her now!' : currentStep === 5 ? 'Bring her to life' : 'Next'}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MultiStepSelector;