import React from "react";
import "./VanillaLanding.css";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from "./firebase"; // Adjust path as needed
import { Camera, MessageCircleHeart, AudioLines} from "lucide-react";

const endpoint = "https://lollipopchat-ca692e070ff0.herokuapp.com"; //https://lollipopchat-ca692e070ff0.herokuapp.com

const Reviews = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);
  
    const reviews = [
      {
        text: "She's like your crush, but actually in your DMs.",
        highlight: "Adam22"
      },
      {
        text: "It's like the movie Her, but I can play with it. We've been waiting for this.",
        highlight: "TherapistOF"
      },
      {
        text: "You can make your own girl. The whole personality, language and looks.",
        highlight: "Picklehammer"
      },
      {
        text: "There is no way all the photos and voice are AI. Roleplay is wild.",
        highlight: "THICColasCage"
      }
    ];
  
    useEffect(() => {
      const interval = setInterval(() => {
        setIsAnimating(true);
        setTimeout(() => {
          setCurrentIndex((prev) => (prev + 1) % reviews.length);
          setIsAnimating(false);
        }, 500);
      }, 5000);
  
      return () => clearInterval(interval);
    }, []);
  
    return (
      <div className="vanillalanding-reviews-container">
        <div className="vanillalanding-reviews-card">
          <div className={`vanillalanding-reviews-content ${
            isAnimating ? 'vanillalanding-content-fadeout' : 'vanillalanding-content-fadein'
          }`}>
            <div className="vanillalanding-reviews-highlight">{reviews[currentIndex].highlight}</div>
            <div className="vanillalanding-reviews-text">{reviews[currentIndex].text}</div>
          </div>
        </div>
      </div>
    );
  };

const AnimatedStats = () => {
  const [currentStatIndex, setCurrentStatIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const stats = [
    { number: "50k+", text: "Messages sent every day" },
    { number: "1M+", text: "Lonely souls cured" },
    { number: "#1", text: "Top rated by reviewers" },
  ];

  // App.js or shared layout
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const fbclid = params.get("fbclid");
    if (fbclid) localStorage.setItem("fbclid", fbclid);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);
      setTimeout(() => {
        setCurrentStatIndex((prev) => (prev + 1) % stats.length);
        setIsAnimating(false);
      }, 500); // Half of the animation duration
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="stats">
      {/* Desktop version */}
      <div className="stats-desktop">
        {stats.map((stat, index) => (
          <div key={index} className="stat">
            <div className="stat-number">{stat.number}</div>
            <div className="stat-text">{stat.text}</div>
          </div>
        ))}
      </div>

      {/* Mobile version with animation */}
      <div className="stats-mobile">
        <div className="stat">
          <div
            className={`stat-number ${isAnimating ? "fade-out" : "fade-in"}`}
          >
            {stats[currentStatIndex].number}
          </div>
          <div className={`stat-text ${isAnimating ? "fade-out" : "fade-in"}`}>
            {stats[currentStatIndex].text}
          </div>
        </div>
      </div>
    </div>
  );
};

const StickyButton = ({ onGetStarted }) => {
  const [isVisible, setIsVisible] = useState(false);
  const heroRef = useRef(null);

  useEffect(() => {
    const heroSection = document.querySelector(".hero-section");
    const observer = new IntersectionObserver(
      ([entry]) => {
        // When hero section is not visible, show the button
        setIsVisible(!entry.isIntersecting);
      },
      {
        threshold: 0.1, // Trigger when 10% of hero section is visible/invisible
        rootMargin: "-10px", // Small offset to make transition smoother
      }
    );

    if (heroSection) {
      observer.observe(heroSection);
    }

    return () => {
      if (heroSection) {
        observer.unobserve(heroSection);
      }
    };
  }, []);

  return (
    <button
      className={`sticky-chat-button ${isVisible ? "visible" : ""}`}
      onClick={onGetStarted}
    >
      Go to chat!
    </button>
  );
};

const LandingPage = () => {
  const featuresRef = useRef(null);
  const companionsRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isTosModalOpen, setIsTosModalOpen] = useState(false);
  const [destination, setDestination] = useState("/");
  const navigate = useNavigate();
  const provider = new GoogleAuthProvider();

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition =
        window.scrollY || document.documentElement.scrollTop;
      setIsScrolled(scrollPosition > 50);
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleGetStarted = (dest) => {
    navigate(dest);
  };

  const TosModal = ({ onClose }) => {
    const [emailConsent, setEmailConsent] = useState(false);

    const signInWithGoogleAndConsent = async () => {
      try {
        const result = await signInWithPopup(auth, provider);

        try {
          const response = await fetch(`${endpoint}/store-consent`, {
            method: "POST",
            headers: {
              Authorization: "Bearer " + (await result.user.getIdToken()),
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: result.user.email,
              emailConsent: emailConsent,
              consentDate: new Date().toISOString(),
            }),
          });

          if (!response.ok) {
            console.error("Failed to store consent");
          }
        } catch (error) {
          console.error("Error storing consent:", error);
        }

        setIsTosModalOpen(false);
        navigate(destination);
      } catch (error) {
        console.error("Error signing in with Google:", error);
      }
    };

    return (
      <div className="backdrop" onClick={onClose}>
        <div className="modal" onClick={(e) => e.stopPropagation()}>
          <div className="tosmodalcontent">
            <div className="oneclicksignup">
              Sign up with one click, get free tokens!
            </div>
            <div className="accepttos">
              I am 18 years of age or older,
              <br />
              and{" "}
              <a
                href="https://lollipop.chat/tos"
                target="_blank"
                style={{
                  textDecoration: "underline",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                I accept the terms and conditions.
              </a>
            </div>

            <div style={{ marginBottom: "20px", textAlign: "center" }}>
              <label className="flex items-center gap-2 text-sm text-white">
                <input
                  type="checkbox"
                  className="w-4 h-4"
                  checked={emailConsent}
                  onChange={(e) => setEmailConsent(e.target.checked)}
                />
                <span className="accepttos">
                  I agree to receive email updates from Lollipop.chat
                </span>
              </label>
            </div>

            <div
              className="googleLogin logindesktop"
              onClick={signInWithGoogleAndConsent}
            >
              Sign in with Google
            </div>
            <div
              className="googleLogin loginmobile"
              onClick={signInWithGoogleAndConsent}
            >
              Sign in with Google
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <StickyButton onGetStarted={() => handleGetStarted("/")} />

      <nav className={`nav ${isScrolled ? "nav-scrolled" : ""}`}>
        <div className="nav-left">
          <div className="nav-links">
          <a
              href="#companions"
              onClick={(e) => {
                e.preventDefault();
                scrollToSection(companionsRef);
              }}
            >
              Companions
            </a>
            <a
              href="#features"
              onClick={(e) => {
                e.preventDefault();
                scrollToSection(featuresRef);
              }}
            >
              Features
            </a>

            <a href="mailto:lenny@lollipop.chat">Contact</a>
          </div>
        </div>
        <button className="start-button" onClick={() => handleGetStarted("/")}>
          Go to chat!
        </button>
      </nav>

      <div className="landing-container">
        <div className="hero-section">
          <div className="background">
            <video
              src="vanillavideo.mp4"
              alt="Background"
              autoPlay
              muted
              loop
              playsInline
            />
            <div className="video-overlay"></div> {/* Add this new div */}
          </div>

          <div className="main-content">
            <h1 className="main-heading">
              Feel a real connection.
            </h1>

            <p className="sub-heading">
              The <b>most advanced</b> romantic AI in the world.{" "}
            </p>
            
            <div className="feature-labels" style={{marginBottom:"10px"}}>
                <span className="feature-label"><MessageCircleHeart /> Text <span style={{opacity:0.7}}>her</span></span>
                <div className="label-divider" />
                <span className="feature-label"><Camera /> See <span style={{opacity:0.7}}>her</span></span>
                <div className="label-divider" />
                <span className="feature-label"> <AudioLines />Hear <span style={{opacity:0.7}}>her</span></span>
            </div>
            <button
              className="start-button vanilla-start-button-main"
              style={{ width: "200px", height: "60px", fontSize: "20px", marginTop: "50px", marginBottom:"20px"}}
              onClick={() => handleGetStarted("/")}
            >
              Meet the girls
            </button>
          </div>

          <Reviews />
        </div>

        <div
          className="companions-section-vanilla"
          ref={companionsRef}
          id="companions"
        >
          <h2 className="section-title">Meet Your Perfect Companion</h2>
          <div className="companions-container">
            <div
              className="companion-card"
              onClick={() => handleGetStarted("/?lollipopgirl=chat")}
            >
              <img
                src="annamariagreeting.png"
                alt="Anna"
                className="companion-image"
              />
              <div className="picCta">Chat with Anna!</div>
              <div className="companion-info">
                <h3 className="companion-name">Anna Maria</h3>
                <p>Empathetic and caring</p>
              </div>
            </div>
            <div
              className="companion-card"
              onClick={() => handleGetStarted("/?lollipopgirl=heidi")}
            >
              <img src="heidi1.png" alt="Heidi" className="companion-image" />
              <div className="picCta">Chat with Heidi!</div>
              <div className="companion-info">
                <h3 className="companion-name">Heidi</h3>
                <p>Can get a bit crazy</p>
              </div>
            </div>
            <div
              className="companion-card"
              onClick={() => handleGetStarted("/?lollipopgirl=amelia")}
            >
              <img
                src="ameliagreeting.png"
                alt="Amelia"
                className="companion-image"
              />
              <div className="picCta">Chat with Amelia!</div>

              <div className="companion-info">
                <h3 className="companion-name">Amelia</h3>
                <p>The best friend type</p>
              </div>
            </div>
          </div>
        </div>

        <div className="benefits-section" ref={featuresRef} id="features">
          <h2 className="section-title">What makes Lollipop amazing?</h2>

          <div className="benefit-row">
            <div className="benefit-image">
              <AnimatedChat />
            </div>
            <div className="benefit-content">
              <h3>Always There For You</h3>
              <p>
                Whether you're feeling down at 3 AM or need someone to celebrate
                with at noon, your Lollipop companion is always available. Get
                emotional support, engaging conversations, and genuine
                companionship whenever you need it.
              </p>
            </div>
          </div>

          <div className="benefit-row reverse">
            <div className="benefit-image">
              <ImageSlideshow />
            </div>
            <div className="benefit-content">
              <h3>Photorealistic images</h3>
              <p>
                It's not just a chat. We pride ourselves in realism. What's a
                chat without photorealistic images?
              </p>
            </div>
          </div>

          <div className="benefit-row">
            <div className="benefit-image">
              <AudioPlayer />
            </div>
            <div className="benefit-content">
              <h3>Voice messages</h3>
              <p>
                Did we mention Lollipop does ultra-realistic voice messages?
                Just ask, and your companion will talk to you in voice.
              </p>
            </div>
          </div>
        </div>

        <footer className="landing-footer">
          <div className="landing-footer-content">
            <div className="landing-footer-left">
              <p className="landing-footer-description">
                Lollipop Chat offers an experience of hyperrealistic
                companionship through state-of-the-art technologies. Generate
                realistic photos, life-like voice messages, chat with no limits,
                and much more.
              </p>
              <p className="landing-footer-copyright">
                © 2024 Lollipop All Rights Reserved
              </p>
            </div>
            <div className="landing-footer-links">
              <a
                href="mailto:lenny@lollipop.chat"
                className="landing-footer-link"
              >
                Contact Us
              </a>
              <a
                href="mailto:lenny@lollipop.chat?subject=Job%20Application"
                className="landing-footer-link"
              >
                I Want a Job
              </a>
              <a
                href="https://lollipop.chat/tos"
                className="landing-footer-link"
              >
                Terms of Service
              </a>
            </div>
          </div>
        </footer>
      </div>
      {isTosModalOpen && <TosModal onClose={() => setIsTosModalOpen(false)} />}
    </>
  );
};

const AnimatedChat = () => {
  const [messages, setMessages] = useState([]);
  const chatContainerRef = useRef(null);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const conversationLoop = [
    { id: 1, type: "ai", text: "Hi! How was your day? 😊", delay: 0 },
    {
      id: 2,
      type: "user",
      text: "Pretty rough actually... Work was stressful",
      delay: 2000,
    },
    {
      id: 3,
      type: "ai",
      text: "I'm sorry to hear that. Want to talk about what happened?",
      delay: 4000,
    },
    {
      id: 4,
      type: "user",
      text: "Just feeling overwhelmed with deadlines",
      delay: 6000,
    },
    {
      id: 5,
      type: "ai",
      text: "That must be difficult. Remember last time you felt this way? You handled it really well by breaking tasks into smaller steps.",
      delay: 8000,
    },
    {
      id: 6,
      type: "user",
      text: "You're right, I did do that... Thanks for remembering ❤️",
      delay: 10000,
    },
  ];

  useEffect(() => {
    const addMessage = (message) => {
      setMessages((prev) => [...prev, message]);
    };

    const startConversation = () => {
      setMessages([]); // Clear messages
      conversationLoop.forEach((message) => {
        setTimeout(() => {
          addMessage(message);
        }, message.delay);
      });
    };

    startConversation();
    const interval = setInterval(startConversation, 12000); // Restart after last message

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="chat-container-vanilla">
      <div className="chat-header">
        <div className="chat-avatar">
          <img src="ninagreeting.png" alt="AI Avatar" />
        </div>
        <div className="chat-status">
          <div className="chat-name">Nina</div>
          <div className="chat-indicator">Always here for you</div>
        </div>
      </div>

      <div className="chat-messages" ref={chatContainerRef}>
        {messages.map((message) => (
          <div
            key={message.id}
            className={`chat-message ${message.type === "ai" ? "ai" : "user"}`}
          >
            {message.text}
          </div>
        ))}
      </div>
    </div>
  );
};

// Add this component in the same file, before the export

const ImageSlideshow = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = ["nina1.png", "heidi2.png", "abena3.png"];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="slideshow-container-vanilla">
      {images.map((image, index) => (
        <div
          key={index}
          className="slideshow-image"
          style={{
            opacity: index === currentImageIndex ? 1 : 0,
            backgroundImage: `url(${image})`,
          }}
        />
      ))}
    </div>
  );
};

const AudioPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const audioRef = useRef(null);
  const [currentTime, setCurrentTime] = useState("0:00");
  const [duration, setDuration] = useState("0:00");

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs.toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    const audio = audioRef.current;

    const handleTimeUpdate = () => {
      const progress = (audio.currentTime / audio.duration) * 100;
      setProgress(progress);
      setCurrentTime(formatTime(audio.currentTime));
    };

    const handleLoadedMetadata = () => {
      setDuration(formatTime(audio.duration));
    };

    audio.addEventListener("timeupdate", handleTimeUpdate);
    audio.addEventListener("loadedmetadata", handleLoadedMetadata);

    return () => {
      audio.removeEventListener("timeupdate", handleTimeUpdate);
      audio.removeEventListener("loadedmetadata", handleLoadedMetadata);
    };
  }, []);

  const handleProgressChange = (e) => {
    const newProgress = e.target.value;
    const time = (newProgress / 100) * audioRef.current.duration;
    audioRef.current.currentTime = time;
    setProgress(newProgress);
  };

  return (
    <div className="audio-wrap">
      <audio ref={audioRef} src="vanillasound.mp3" />

      <div className="audio-content">
        <div className="audio-avatar">
          <img src="ninagreeting.png" alt="Nina" />
          <button onClick={togglePlay} className="play-button">
            {isPlaying ? "❚❚" : "▶"}
          </button>
        </div>

        <div className="audio-controls">
          <div className="progress-wrap">
            <input
              type="range"
              value={progress}
              onChange={handleProgressChange}
              className="progress-slider"
            />
            <div className="time-info">
              <span>{currentTime}</span>
              <span>{duration}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
