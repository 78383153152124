import React, { useState, useEffect } from "react";
import "./NewPaymentModal.css";
import { Heart, Check, Lock, Shield, CopyX, X} from "lucide-react";

const PaymentModal = ({ onClose, handlePayment, isSubscribed }) => {
  const TIMER_DURATION = 59 * 60; // 59 minutes in seconds

  // Get initial time or start new timer
  const getInitialTime = () => {
    const savedStartTime = localStorage.getItem('paymentModalStartTime');
    const startTime = savedStartTime || Date.now().toString();
    
    if (!savedStartTime) {
      localStorage.setItem('paymentModalStartTime', startTime);
    }

    const elapsedSeconds = Math.floor((Date.now() - parseInt(startTime)) / 1000);
    const remainingSeconds = TIMER_DURATION - (elapsedSeconds % TIMER_DURATION);

    return {
      hours: Math.floor(remainingSeconds / 3600),
      minutes: Math.floor((remainingSeconds % 3600) / 60),
      seconds: remainingSeconds % 60
    };
  };

  const [timeLeft, setTimeLeft] = useState(getInitialTime());
  const [selectedPlan, setSelectedPlan] = useState(null);

  useEffect(() => {
    const timer = setInterval(() => {
      const startTime = parseInt(localStorage.getItem('paymentModalStartTime'));
      const elapsedSeconds = Math.floor((Date.now() - startTime) / 1000);
      const remainingSeconds = TIMER_DURATION - (elapsedSeconds % TIMER_DURATION);

      setTimeLeft({
        hours: Math.floor(remainingSeconds / 3600),
        minutes: Math.floor((remainingSeconds % 3600) / 60),
        seconds: remainingSeconds % 60
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const plans = [
    {
      id: "starter",
      title: "Starter Fantasy",
      description: "Love pussy, but want a sweet deal?",
      originalPrice: 5.99,
      price: 2.99,
      discount: "50% OFF",
      tokens: "25 tokens",
      priceId: isSubscribed ? "price_1OUnhhEsKxxKHfJy2BGMvbsK" : "price_1QGHBPEsKxxKHfJyyPE9PujY"
    },
    {
      id: "enhanced",
      title: "Enhanced Ecstasy",
      description: "You're an absolute legend, who needs more than the average guy.",
      originalPrice: 24.99,
      price: 9.99,
      discount: "60% OFF",
      bestValue: true,
      tokens: "100 tokens",
      priceId: isSubscribed ? "price_1OUnjlEsKxxKHfJy8urThzlC" : "price_1QGfm8EsKxxKHfJy9FrKBIDs"
    },
    {
      id: "limitless",
      title: "Limitless Lust",
      description: "Johnny Sins himself is afraid of you. We are too. Plan of a king.",
      originalPrice: 199.99,
      price: 49.99,
      discount: "75% OFF",
      tokens: "1000 tokens",
      priceId: isSubscribed ? "price_1OUnkXEsKxxKHfJysI3RH4pl" : "price_1QGfn9EsKxxKHfJyjfN7ms1R"
    }
  ];

  const handlePlanSelection = (planId) => {
    setSelectedPlan(planId);
  };

  const handleContinue = () => {
    if (selectedPlan) {
      const plan = plans.find(p => p.id === selectedPlan);
      console.log(`Selected plan: ${plan.title}`);
      console.log(`Price ID: ${plan.priceId}`);
      console.log("IS subscribed: ", isSubscribed);
      handlePayment(plan.priceId);
    }
  };

  return (
    <div className="paymentmodal-backdrop" onClick={onClose}>

      <div className="paymentmodal-closebutton" onClick={onClose}>
        <CopyX color="#ffffff" />
      </div>
      <div className="paymentmodal-container" onClick={(e) => e.stopPropagation()}>
        <div className="paymentmodal-content">
        <div className="paymentmodal-closebutton-mobile" onClick={onClose}>
        <X color="#1a1a1a" />
        </div>
          <div className="paymentmodal-header">
            <h2 className="paymentmodal-title">
            NEW YEAR SALE
            <div style={{fontSize:"15px", fontWeight:500}}>Up to 75% off</div>

            </h2>
            <p className="paymentmodal-subtitle">
              You're new, so you can enjoy all these{" "}
              <span className="paymentmodal-highlight">massive discounts.</span>{" "}
              Happy New Year!
            </p>
          </div>

          <div className="paymentmodal-main">
            <div className="paymentmodal-left-column">
              <div className="paymentmodal-plans">
                {plans.map((plan) => (
                  <div
                    key={plan.id}
                    className={`paymentmodal-plan ${
                      plan.bestValue ? "best-value" : ""
                    } ${selectedPlan === plan.id ? "selected" : ""}`}
                    onClick={() => handlePlanSelection(plan.id)}
                  >
                    <div className="paymentmodal-discount-tag">
                      {plan.discount}
                    </div>
                    <div className="paymentmodal-plan-title">{plan.title}</div>
                    <span className="paymentmodal-plan-tokens">
                      {plan.tokens}
                    </span>
                    <div className="paymentmodal-plan-description">
                      {plan.description}
                    </div>
                    <div className="paymentmodal-price-info">
                      <div className="paymentmodal-original-price">
                        €{plan.originalPrice.toFixed(2)}
                      </div>
                      <div className="paymentmodal-current-price">
                        €{plan.price.toFixed(2)} <span style={{fontSize:"14px", fontWeight:"400", opacity:0.8}}>{!isSubscribed ? "/ month" : ""}</span>
                      </div>
                    </div>
                    {plan.bestValue && (
                      <div className="paymentmodal-best-value-badge">
                        Most popular
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <div className="paymentmodal-footer">
              <button
                className="paymentmodal-cta-button"
                onClick={handleContinue}
                disabled={!selectedPlan}
                >
                {!selectedPlan 
                    ? "Select your plan" 
                    : isSubscribed 
                    ? "Get the tokens!" 
                    : "Get premium!"}
                </button>

                <div className="paymentmodal-secure-payments">
                  <div className="paymentmodal-secure-text">
                    <img src="icons/stripeicon.svg" alt="stripe" width="24px" />
                    Secure payments via Stripe
                  </div>
                </div>
              </div>
            </div>

            {!isSubscribed ?

            <div className="paymentmodal-right-column">
              <div className="paymentmodal-info">
                {/* Premium Benefits Section */}
                <div className="paymentmodal-benefits">
                  <div className="paymentmodal-timer">
                    <div className="paymentmodal-time-block">
                      <div className="paymentmodal-time-value">
                        {String(timeLeft.hours).padStart(2, "0")}
                      </div>
                      <div className="paymentmodal-time-label">Hours</div>
                    </div>
                    <div className="paymentmodal-separator">:</div>
                    <div className="paymentmodal-time-block">
                      <div className="paymentmodal-time-value">
                        {String(timeLeft.minutes).padStart(2, "0")}
                      </div>
                      <div className="paymentmodal-time-label">Minutes</div>
                    </div>
                    <div className="paymentmodal-separator">:</div>
                    <div className="paymentmodal-time-block">
                      <div className="paymentmodal-time-value">
                        {String(timeLeft.seconds).padStart(2, "0")}
                      </div>
                      <div className="paymentmodal-time-label">Seconds</div>
                    </div>
                  </div>
                  <hr style={{ opacity: 0.2 }} />
                  <h3 className="paymentmodal-benefits-title">
                    PREMIUM BENEFITS
                  </h3>
                  <div className="paymentmodal-benefits-list">
                    <div className="paymentmodal-info-item">
                      <Heart size={18} color="#F34CCF" fill="#F34CCF" />
                      Instantly get selected token package
                    </div>
                    <div className="paymentmodal-info-item">
                      <Heart size={18} color="#F34CCF" fill="#F34CCF" />
                      Unblurred realistic photos
                    </div>
                    <div className="paymentmodal-info-item">
                      <Heart size={18} color="#F34CCF" fill="#F34CCF" />
                      Create your own dream girl
                    </div>
                    <div className="paymentmodal-info-item">
                      <Heart size={18} color="#F34CCF" fill="#F34CCF" />
                      Get extra tokens with big discounts. Forever.
                    </div>
                  </div>
                </div>

                {/* We Can Ensure Section */}
                <div className="paymentmodal-assurance">
                  <h3 className="paymentmodal-assurance-title">We promise:</h3>
                  <div className="paymentmodal-assurance-list">
                    <div className="paymentmodal-info-item">
                      <Check size={18} color="#F34CCF" />
                      Honest pricing. Photos, voice, chats = 1 token each.
                    </div>
                    <div className="paymentmodal-info-item">
                      <Check size={18} color="#F34CCF" />
                      No Adult transaction in your bank statement
                    </div>
                    <div className="paymentmodal-info-item">
                      <Check size={18} color="#F34CCF" />
                      Fully encrypted data.
                    </div>
                    <div className="paymentmodal-info-item">
                      <Check size={18} color="#F34CCF" />
                      Easily cancel plans any time!
                    </div>
                  </div>
                </div>

                {/* Payment Methods Section */}
                <div className="paymentmodal-payment-section">
                  <div className="paymentmodal-payment-title">
                    Lollipop accepts:
                  </div>
                  <div className="paymentmodal-payment-logos">
                    <img
                      src="/paymentmethods/visa.png"
                      alt="Visa"
                      className="payment-logo"
                    />
                    <img
                      src="/paymentmethods/mastercard.png"
                      alt="Mastercard"
                      className="payment-logo"
                    />
                    <img
                      src="/paymentmethods/applepay.png"
                      alt="Apple Pay"
                      className="payment-logo"
                    />
                    <img
                      src="/paymentmethods/googlepay.svg"
                      alt="Google Pay"
                      className="payment-logo"
                    />
                    <img
                      src="/paymentmethods/paypal.png"
                      alt="PayPal"
                      className="payment-logo"
                    />
                    <img
                      src="/paymentmethods/revolut.png"
                      alt="Revolut"
                      className="payment-logo"
                    />
                  </div>
                </div>
              </div>
            </div>
            :
            <div className="paymentmodal-right-column">
            <div className="paymentmodal-info">
              {/* Premium Benefits Section */}
              <div className="paymentmodal-benefits">
                <h3 className="paymentmodal-benefits-title">
                  You are subscribed, want more tokens?
                </h3>
                <div className="paymentmodal-benefits-list">
                  <div className="paymentmodal-info-item">
                    <Heart size={18} color="#F34CCF" fill="#F34CCF" />
                    Instantly get selected tokens added
                  </div>
                  <div className="paymentmodal-info-item">
                    <Heart size={18} color="#F34CCF" fill="#F34CCF" />
                    Continue all your current benefits
                  </div>
                  <div className="paymentmodal-info-item">
                    <Heart size={18} color="#F34CCF" fill="#F34CCF" />
                    Enjoy the discounts!
                  </div>
                </div>
              </div>

              {/* We Can Ensure Section */}
              <div className="paymentmodal-assurance">
                <h3 className="paymentmodal-assurance-title">We promise:</h3>
                <div className="paymentmodal-assurance-list">
                  <div className="paymentmodal-info-item">
                    <Check size={18} color="#F34CCF" />
                    Honest pricing. Photos, voice, chats = 1 token each.
                  </div>
                  <div className="paymentmodal-info-item">
                    <Check size={18} color="#F34CCF" />
                    No Adult transaction in your bank statement
                  </div>
                  <div className="paymentmodal-info-item">
                    <Check size={18} color="#F34CCF" />
                    Fully encrypted data.
                  </div>
                  <div className="paymentmodal-info-item">
                    <Check size={18} color="#F34CCF" />
                    Easily cancel plans any time!
                  </div>
                </div>
              </div>

              {/* Payment Methods Section */}
              <div className="paymentmodal-payment-section">
                <div className="paymentmodal-payment-title">
                  Lollipop accepts:
                </div>
                <div className="paymentmodal-payment-logos">
                  <img
                    src="/paymentmethods/visa.png"
                    alt="Visa"
                    className="payment-logo"
                  />
                  <img
                    src="/paymentmethods/mastercard.png"
                    alt="Mastercard"
                    className="payment-logo"
                  />
                  <img
                    src="/paymentmethods/applepay.png"
                    alt="Apple Pay"
                    className="payment-logo"
                  />
                  <img
                    src="/paymentmethods/googlepay.svg"
                    alt="Google Pay"
                    className="payment-logo"
                  />
                  <img
                    src="/paymentmethods/paypal.png"
                    alt="PayPal"
                    className="payment-logo"
                  />
                  <img
                    src="/paymentmethods/revolut.png"
                    alt="Revolut"
                    className="payment-logo"
                  />
                </div>
              </div>
            </div>
          </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentModal;